<!-- eslint-disable vue/no-multiple-template-root -->

<template>
    <slot
        name="navbar"
        :on-click-hamburger="onClickHamburger"
        :on-click-search="onClickSearch"
        :is-open="isOpen"
        :has-scrolled="hasScrolled"
        :search-is-active="searchIsActive"
    />

    <div
        class="navigation-flyout"
        :class="{ 'navigation-flyout--open': isOpen }"
    >
        <slot
            name="flyout"
            :is-open="isOpen"
            :on-click-hamburger="onClickHamburger"
        />
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const searchIsActive = computed({
    get: () => store.state.searchState.searchIsActive,
    set: (value) => store.commit("setSearchIsActive", value),
});
const isOpen = computed({
    get: () => store.state.mobileMenuState.mobileMenuIsOpen,
    set: (value) => store.commit("setMobileMenuIsOpen", value),
});

const hasScrolled = ref(false);

function onClickHamburger() {
    if (searchIsActive.value) {
        searchIsActive.value = false;
    }
    isOpen.value = !isOpen.value;
}

function onClickSearch() {
    if (isOpen.value) {
        isOpen.value = false;
    }
    searchIsActive.value = !searchIsActive.value;
}

function onScroll() {
    hasScrolled.value = window.scrollY > 0;
}

onMounted(() => {
    window.addEventListener("scroll", onScroll);
});

onBeforeUnmount(() => {
    window.removeEventListener("scroll", onScroll);
});
</script>
