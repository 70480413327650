<template>
    <div class="updates__item">
        <div class="updates__content">
            <h6 v-if="date" class="is-text">
                {{ date }}
            </h6>

            <a
                :href="item.url"
                class="updates__title-link"
                :aria-label="getAriaLinkText(item.url)"
            >
                <div class="updates__title">
                    {{ item.title }}
                </div>
            </a>

            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="updates__paragraph" v-html="item.shortDescription" />

            <div
                v-if="'breadcrumbs' in item && item.breadcrumbs.length"
                class="updates__details"
            >
                <div class="breadcrumb-list breadcrumb-list--updates">
                    <a
                        v-for="breadcrumb in item.breadcrumbs"
                        :key="breadcrumb.title"
                        class="breadcrumb-item breadcrumb-item--updates"
                        :href="breadcrumb.url"
                        :aria-label="getAriaLinkText(breadcrumb.url)"
                        >{{ breadcrumb.title }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import getAriaLinkText from "../helpers.js";

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
});

const date = computed(() => {
    switch (props.item.type) {
        case "disruptionDetail":
            return props.item.startTime;
        case "pressRelease":
            return props.item.datePublished;
        default:
            return null;
    }
});
</script>
