<template>
    <div>
        <ais-refinement-list
            v-if="attribute"
            v-slot="{ items, refine, createURL }"
            :attribute="attribute"
            :limit="99"
            element="ul"
            class="c-filtering c-filtering--is-root-level"
            :sort-by="['name']"
        >
            <li
                v-for="item in items"
                :key="item.value"
                class="c-filtering__item"
            >
                <check-input>
                    <template #default>
                        <div>
                            <a
                                class="c-check-input__link"
                                :href="createURL(item.value)"
                                :aria-label="
                                    getAriaLinkText(createURL(item.value))
                                "
                                @click.prevent="refine(item.value)"
                            >
                                <label
                                    class="c-check-input__label"
                                    :for="kebab(item.value)"
                                >
                                    <input
                                        :id="kebab(item.value)"
                                        type="checkbox"
                                        :checked="item.isRefined"
                                        class="c-check-input__input"
                                    />
                                    <span class="c-check-input__box" />
                                    {{ convertLabel(item.label) }} ({{
                                        item.count
                                    }})</label
                                >
                            </a>
                        </div>
                    </template>
                </check-input>
            </li>
        </ais-refinement-list>

        <ais-hierarchical-menu
            v-if="attributes"
            v-slot="{ items, refine, createURL }"
            :attributes="attributes"
            :root-path="rootPath"
        >
            <hierarchical-menu-list
                :items="items"
                :refine="refine"
                :create-url="createURL"
                :convert-label="convertLabel"
                :root-path="rootPath"
                :is-root-level="true"
                :filter-depth="filterDepth"
            />
        </ais-hierarchical-menu>
    </div>
</template>

<script setup>
import { kebab } from "../../filters";
import getAriaLinkText from "../../helpers.js";

defineProps({
    attribute: {
        type: String,
        default: null,
    },
    attributes: {
        type: Array,
        default: null,
    },
    filterDepth: {
        type: Number,
        default: 1,
    },
    rootPath: {
        type: String,
        default: null,
    },
});

defineEmits(["onRefine"]);

const convertLabel = (label) => {
    const stringArray = label.split(" > ");
    return stringArray[stringArray.length - 1];
};
</script>
