<template>
    <div v-if="recentSearches.length">
        <div class="u-margin-bottom-tiny">
            <h6>
                <slot></slot>
            </h6>
        </div>
        <ul class="results">
            <li
                v-for="(search, index) in recentSearches.slice().reverse()"
                :key="index"
                class="results__item"
            >
                <a
                    class="link link--decorated"
                    :href="search.url"
                    :aria-label="getAriaLinkText(search.url)"
                >
                    {{ search.title }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import searchHistory from "../../services/search/searchHistory";
import getAriaLinkText from "../../helpers.js";

defineProps({
    searchBaseUrl: {
        type: String,
        required: true,
    },
});

const recentSearches = ref([]);

onMounted(() => {
    recentSearches.value = searchHistory.all();
});
</script>
