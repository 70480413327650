const helpers = (() => {
    const handleDataLayerPush = (event, attributes) => {
        event.preventDefault();

        if (attributes) {
            window.dataLayer.push(attributes);
        }

        if (event?.currentTarget?.target === "_blank") {
            window.open(event.currentTarget.href, "_blank").focus();
            return;
        }

        window.location.href = event?.currentTarget?.href;
    };

    return { handleDataLayerPush };
})();

window.helpers = helpers;
