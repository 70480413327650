import { translate } from "./services/staticTranslations/staticTranslations.service";
import kebabCase from "lodash/kebabCase";

const t = (message, options = null) => translate(message, options);

const currency = (value) =>
    new Intl.NumberFormat(window.dataStatic.locale, {
        style: "currency",
        currency: "EUR",
    })
        .format(value)
        .replace(/\s/g, "");

const percentage = (value) =>
    new Intl.NumberFormat(window.dataStatic.locale, {
        style: "percent",
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    }).format(value);

const kebab = (value) => kebabCase(value);

export { t, currency, percentage, kebab };
