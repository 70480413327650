<template>
    <div v-if="servicePackage">
        <slot />
        <calculation-results
            :service-packages="servicePackage"
            :show-header="false"
        />
    </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    servicePackages: {
        type: Array,
        required: true,
    },
    items: {
        type: Array,
        required: true,
    },
});

const servicePackage = computed(() => {
    const year = props.items.find((item) => item.attribute === "year");
    const correctCategory = props.items.find((item) => {
        return (
            item.attribute === "categoriesRates.title" &&
            item.refinements.find(
                (refinement) => refinement.value === "Tarieven leden"
            )
        );
    });

    if (!correctCategory || !year) {
        return null;
    }

    const item = props.servicePackages.find((servicePackage) => {
        return servicePackage.version === year.refinements[0].value;
    });

    if (!item) {
        return null;
    }

    return item.content;
});
</script>
